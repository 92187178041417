@import '../../styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 20px;
}

.incorrect {
  color: red;
  font-size: 13px;
}
