@import '../../styles/variables.scss';

.checkoutSelector {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.btnCheckout {
  cursor: pointer;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  background-color: transparent;
  font-variant: small-caps;
  color: $details;
}

.btnCheckoutActive {
  @extend .btnCheckout;
  color: $text;
}
