@import '../../styles/variables.scss';

.root {
  min-width: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $base-yellow;

  h1 {
    margin: 50px 0 35px 0;
    font-size: 34px;
  }

  p {
    font-size: 22px;
    margin: 25px 0;
  }

  button {
    width: fit-content;
    box-sizing: border-box;
    padding: 4px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    filter: brightness(0.9);
    margin: 15px;

    &:hover {
      filter: brightness(1);
    }
  }
}
