@import '../../styles/variables.scss';

.product {
  max-width: 440px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.block {
  display: flex;
}

.title {
  color: $text;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 5px;
}

.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  width: 90px;
  padding: 5px;
  border: 1px solid $text;
}

.details {
  min-width: 50%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.img {
  height: 100%;
  width: 100%;
  max-height: 95px;
  max-width: 90px;
  object-fit: cover;
  overflow: hidden;
}

.blockCode {
  display: flex;
  align-items: center;
}

.code {
  color: $details;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  margin-left: 50px;
}

.quantity {
  @extend .title;
  font-size: 10px;
  line-height: 12px;
}

.quantity > span {
  padding-left: 3px;
}

.price {
  color: $base-yellow;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  text-align: end;
}

@media (min-width: $tablet) {
  .details {
    padding-left: 25px;
  }

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .price {
    font-size: 18px;
  }
}

@media (min-width: $tablet-max) {
  .code {
    margin-left: 100px;
  }
}
