@import '../../styles/variables.scss';

.notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: calc(100vh / 15) 0px;
  gap: calc(100vh / 25);

  h2 {
    color: $base-yellow;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  h3 {
    color: $base-yellow;
    margin: 10px 20px;
  }

  a {
    text-decoration: underline;
  }
}

.telescope {
  height: calc(100vh / 3);
  max-width: 50vh;
  animation: fall 1s 500ms forwards 1;
  path {
    fill: $base-yellow;
  }
}

@keyframes fall {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(-180deg);
  }
}

@media (min-width: $tablet) {
  .notFoundPage {
    gap: calc(100vh / 15);
  }
}
