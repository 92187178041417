@import '../../styles/variables.scss';

.svg {
  width: 25px;
  height: 25px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  margin-top: 20px;
  background: $background;
  border-top: 1px solid olive;
}

.navLink {
  padding: 10px;
}

.absFooter {
  @extend .footer;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.info {
  display: flex;
  color: $text;
  font-size: 12px;
  align-items: center;
  margin: 5px;
}

.details {
  margin-left: 6px;
}

.details > svg {
  @extend .svg;
}

.section {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: $tablet) {
  .footer {
    flex-direction: row;
    justify-content: center;
  }

  .info {
    font-size: 14px;
  }
}

@media (min-width: $tablet-max) {
  .footer {
    justify-content: space-around;
  }

  .info {
    padding-left: 20px;
    padding-right: 20px;
  }
}
