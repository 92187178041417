@import '../../styles/variables.scss';

.btn {
  padding: 15px 30px;
  background-color: transparent;
  border: 1px solid $base-yellow;
  color: $base-yellow;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 10px;

  &:disabled {
    border: 1px dotted $sale;
    color: $sale;

    &:hover {
      background-color: $details;

      &[data-dis-tip]:not([data-dis-tip=''])::after {
        position: absolute;
        content: attr(data-dis-tip);
        left: 50%;
        top: 50%;
        transform: translateX(-50%);
        background-color: $background;
        outline: 1px solid $sale;
        color: $text;
      }
    }
  }

  &:active {
    background: $active-btn;
    border: $active-btn solid 1px;
    color: $background;
  }

  &:hover {
    border: 1px solid $text;
    color: $text;
    background: $base-yellow;
    cursor: pointer;
  }
}
