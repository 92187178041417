@import '../../styles/variables.scss';

.checkoutPage {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
}

.summary {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.tabs {
  width: 100%;
  min-width: 300px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 12px 10px 15px;
  border: 2px solid $base-yellow;
}

h2 {
  color: $text;
}

@media (min-width: $tablet) {
  .checkoutPage {
    padding: 20px 5px;
  }

  .summary {
    display: flex;
    margin-top: 20px;
  }

  .tabs {
    padding: 12px 30px 20px;
    margin-bottom: 20px;
  }
}

@media (min-width: $tablet-max) {
  .checkoutPage {
    flex-wrap: nowrap;
  }
  .tabs {
    width: 50%;
    margin-left: 30px;
    margin-right: 30px;
    padding: 12px 35px 30px 35px;
    order: 1;
    margin-top: 20px;
  }
  .summary {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    order: 2;
    margin-top: 20px;
  }
}
