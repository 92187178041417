// BREAKPOINTS
$mobile: 320px;
$tablet: 768px;
$tablet-max: 992px;
$desktop: 1366px;

//COLORS
$base-yellow: #ffc500;
$text: #ffffff;
$background: #1e1e1e;
$details: #8f8f89;
$sale: #f44236;
$active-btn: #f7d131;
