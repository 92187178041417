@import '../../styles/variables.scss';

.successful {
  position: fixed;
  top: 15%;
  right: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  & > p {
    background-color: black;
    padding: 15px;
    border: 1px solid lightskyblue;
    color: lightskyblue;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.error {
  @extend .successful;
  & > p {
    border: 1px solid $sale;
    color: $sale;
  }
}
