@import '../../styles/variables.scss';

.wrapper {
  margin-bottom: 20px;
}

.componentWrapper {
  position: relative;
}

.titleSvg {
  width: 50px;
  height: 50px;
  padding-left: 5px;
}

.title {
  display: flex;
  align-items: center;
  color: $text;
  font-weight: 400;
  font-size: 36px;
  text-transform: uppercase;
  margin: 20px;
  align-self: flex-start;
  padding-left: 15%;
}

.img {
  width: 120px;
  margin: 20px;
}

.card {
  position: relative;
}

@media (min-width: $tablet) {
  .wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
  }

  .svg {
    bottom: 64px;
  }

  .img {
    width: 150px;
  }
}

@media (min-width: $tablet-max) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .img {
    width: 200px;
  }
}

@media (min-width: $desktop) {
  .wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
