@import '../../styles/variables.scss';
@media (min-width: $mobile) {
  .filter {
    position: absolute;
    z-index: 1;
    background: $background;
    height: 100%;
    left: 0;
    top: 20px;
    width: 60vw;
    display: none;
    margin-top: 149px;

    label {
      display: inline-flex;
      align-items: center;
    }

    label::before {
      content: '';
      display: inline-block;
      width: 1em;
      height: 1em;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1px solid #adb5bd;
      border-radius: 0.25em;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }

  .conrolBtnsWrapper {
    display: flex;
  }

  .checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .checkbox:checked + label::before {
    border-color: #f7d131;
    background-color: #f7d131;
    background-image: url('../../assets/svg/check-mark.svg');
  }

  .menuBackground {
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
  }

  .closeFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    & > span {
      color: $base-yellow;
      margin-left: 5px;
    }
  }

  .addButton {
    border: 1px solid $base-yellow;
    padding: 5px 10px;
    outline: none;
    background: none;
    color: $base-yellow;
    text-transform: uppercase;
    margin: 10px;
  }

  .priceInputsWrapper {
    display: flex;
    justify-content: space-between;
  }

  .priceInput {
    width: 40%;
  }
}

@media (min-width: $tablet) {
  .filter {
    width: 40vw;
  }
}

@media (min-width: $tablet-max) {
  .addButton {
    cursor: pointer;
    &:hover {
      border: 1px solid $text;
      color: $text;
      background: $base-yellow;
      display: block;
    }
  }

  .closeFilter {
    display: none;
  }

  .menuBackground {
    display: none;
  }

  .filter {
    position: static;
    width: 246px;
    min-width: 246px;
    display: block;
    margin-top: 23px;
  }
}
