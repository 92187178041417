@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  z-index: 3;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.2;
  z-index: 1;
}

.content {
  width: 80vw;
  background-color: black;
  margin: 0 auto;
  z-index: 2;
  padding: 10px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.closeButton {
  align-self: flex-end;
  text-transform: uppercase;
  fill: $base-yellow;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

@media (min-width: $tablet) {
  .content {
    width: 50vw;
  }
}

@media (min-width: $tablet-max) {
  .content {
    width: 40vw;
  }
}

@media (min-width: $desktop) {
  .content {
    width: 35vw;
  }
}
