@import '../../styles/variables.scss';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  min-width: 90%;
  background: $background;
  border: $base-yellow solid 2px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
}

.text {
  color: $text;
  font-weight: 500;
  font-size: 16px;
  padding-top: 10px;
}

.details {
  color: $details;
  font-weight: 400;
  font-size: 7px;
  line-height: 10px;
  padding-bottom: 5px;
}

.price {
  color: $base-yellow;
  font-size: 18px;
  font-weight: 700;
  padding: 5px;
}

//------------------TABLET

@media (min-width: $tablet) {
  .component {
    width: 85%;
  }

  .info {
    padding-left: 10px;
  }

  .text {
    font-size: 18px;
    padding-top: 10px;
  }

  .details {
    padding-bottom: 7px;
  }

  .price {
    font-size: 20px;
    padding: 10px 20px;
  }
}

//------------------TABLET-MAX

@media (min-width: $tablet-max) {
  .component {
    width: 450px;
  }

  .info {
    padding-left: 15px;
  }

  .text {
    font-size: 20px;
    padding-top: 15px;
  }

  .details {
    font-size: 10px;
    line-height: 12px;
  }

  .price {
    color: $base-yellow;
    padding: 15px 25px;
    font-size: 22px;
  }
}

//------------------DESKTOP

@media (min-width: $desktop) {
  .component {
    width: 475px;
  }

  .price {
    font-size: 24px;
  }

  .details {
    font-size: 10px;
    line-height: 12px;
    padding-bottom: 10px;
  }

  .price {
    font-size: 24px;
  }
}
