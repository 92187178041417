@import '../../styles/variables.scss';
@media (min-width: $mobile) {
  .cardContainer {
    width: 190px;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
  }

  .bookmark {
    cursor: pointer;
    position: absolute;
    right: 10px;
    &:hover {
      transform: scale(1.2);
    }
  }

  .iconWrapper {
    width: fit-content;
    height: 240px;
  }

  .img {
    width: 190px;
    height: 240px;
    object-fit: cover;
  }

  .productDecription {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: $text;
    margin-top: 14px;
    margin-bottom: 8px;
  }

  .productPlatform {
    font-size: 12px;
    color: $text;
  }

  .priceWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
  }

  .priceBox {
    display: flex;
    flex-direction: column;
  }

  .price {
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: $base-yellow;
  }

  .previousPrice {
    @extend .price;
    text-decoration: line-through;
    color: $sale;
    font-size: 16px;
  }

  .saleSvg {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -20px;
    left: -20px;
  }

  .addButton {
    border: 1px solid $base-yellow;
    padding: 5px 10px;
    outline: none;
    background: none;
    color: $base-yellow;
    text-transform: uppercase;
  }
  .addButton:active {
    transform: scale(1.1);
  }
}

@media (min-width: $tablet-max) {
  .iconWrapper {
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 10px 1px $active-btn;
    }
  }

  .addButton {
    cursor: pointer;
    &:hover {
      border: 1px solid $text;
      color: $text;
      background: $base-yellow;
    }
  }

  .productDecription:hover {
    text-decoration: underline;
  }
}
