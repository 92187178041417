@import '../../styles/variables.scss';

.OrderConfirmed {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0px;
  //padding: 50px 0px;
  //gap: 75px;
  gap: 30px;

  h2 {
    color: $base-yellow;
    //font-size: 30px;
    font-size: 20px;
    padding: 10px;
    //line-height: 43px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }

  h3 {
    color: $base-yellow;
    text-decoration: underline;
  }
}

.img {
  width: 100px;
  height: 100px;
}

@media (min-width: $tablet) {
  .OrderConfirmed {
    padding: 50px 0px;
    gap: 75px;

    h2 {
      font-size: 30px;
      line-height: 43px;
    }
  }

  .img {
    width: 150px;
    height: 150px;
  }
}
