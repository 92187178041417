@import '../../styles/variables.scss';

.separation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $details;
  margin-top: 20px;
  &::before {
    content: '';
    border: 1px solid $details;
    background-color: $details;
    width: calc(50% - 12px);
  }
  &::after {
    content: '';
    border: 1px solid $details;
    background-color: $details;
    width: calc(50% - 12px);
  }
}

.altAuthorization {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.facebookWrapper {
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $details;
  padding: 7px 0;
  width: 48%;
  color: $details;

  &:hover {
    background: #3b5898cc;
    color: $text;
  }

  & .facebookSvg {
    width: 10px;
    height: 18px;
  }
  & p {
    margin-left: 10px;
    color: inherit;
  }
}

.googleWrapper {
  @extend .facebookWrapper;
  &:hover {
    background: #db4437cc;
  }

  & .googleSvg {
    fill: white;
    width: 14px;
    height: 24px;
  }
}
