@import './styles/variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.3s;
}

body {
  background-color: $background;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: $text;
}

li,
ol {
  list-style: none;
}
