@import '../../styles/variables.scss';

.mainWrapper {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $details;
  display: flex;
  justify-content: space-between;
  & svg {
    cursor: pointer;
  }
}

.imgWrapper {
  display: flex;
  max-width: 80px;
  max-height: 100px;
  min-height: 100px;
  & > * {
    width: 100%;
    border: 1px solid #ffffff;
    object-fit: cover;
  }
}

.infoWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
}

.infoWrapperTitle {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

.infoWrapperTitleText {
  font-size: 14px;
  line-height: 18px;
  color: $text;
}

.infoWrapperTitleCode {
  color: $details;
  align-self: flex-start;
  font-size: 10px;
  margin: 5px 0 10px 0;
}

.priceWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}

.infoWrapperQuantity {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 20px;
}

.infoWrapperQuantityValue {
  font-size: 10px;
  color: $text;
  margin-bottom: 8px;
}

.infoWrapperQuantityBlock {
  display: flex;
  align-items: center;
  min-width: 20%;
}

.infoWrapperQuantityBlockMinus {
  border: 1px solid $text;
  display: flex;
}

.infoWrapperQuantityBlockMinusNotWork {
  border: 1px solid grey;
  display: flex;
}

.infoWrapperQuantityBlockMinusItem {
  fill: $text;
}

.infoWrapperQuantityBlockMinusItemNotWork {
  fill: grey;
}

.infoWrapperQuantityBlockValue {
  color: $text;
  font-size: 16px;
  min-width: 30px;
  text-align: center;
}

.infoWrapperQuantityBlockPlus {
  display: flex;
  border: 1px solid $text;
}

.infoWrapperQuantityBlockPlusItem {
  fill: $text;
}

.priceDeleteWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: end;
  margin-left: 20px;
}

.deleteWrapper {
  display: flex;
  justify-content: flex-end;
}

.deleteWrapperItem {
  fill: white;
}

.priceValue {
  display: flex;
  font-size: 14px;
  color: $base-yellow;
}

.priceValueOneItem {
  display: flex;
  font-size: 14px;
  color: $base-yellow;
  & span {
    white-space: nowrap;
  }
}

@media (min-width: $tablet) {
  .mainWrapper {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  .imgWrapper {
    max-width: 120px;
    min-width: 120px;
    max-height: 170px;
    min-height: 170px;
  }

  .infoWrapperTitleText {
    font-size: 20px;
  }
  .infoWrapperTitleCode {
    font-size: 14px;
  }

  .infoWrapperQuantityBlockValue {
    font-size: 20px;
  }

  .infoWrapperQuantityBlockValue {
    margin: 0 9px;
  }

  .priceValue {
    font-size: 18px;
    & span {
      white-space: nowrap;
    }
  }
  .priceValueOneItem {
    font-size: 18px;
  }
}

@media (min-width: $tablet-max) {
  .mainWrapper {
    max-width: 90%;
  }

  .imgWrapper {
    max-width: 15%;
  }
}
