@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $background;
  position: fixed;
  z-index: 3;
  width: 100vw;
  border-bottom: 1px solid olive;
}

.dummy {
  height: 80px;
}

.nav {
  display: flex;
  align-items: center;
  position: absolute;
  left: 5px;
  top: 75px;
}

.navList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style-type: none;
  background: #8f8f89e6;
  z-index: 1;
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchImg {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

.mobileSearch {
  display: flex;
  position: absolute;
  top: 65px;
  left: 60px;
}

.menuBox {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.searchDesktop {
  display: none;
}

.none {
  display: none;
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 5px 10px 10px;
  position: relative;
  cursor: pointer;
  min-width: 60px;
}

.navLine {
  width: 2px;
  height: 28px;
  border-radius: 1px;
  background: $base-yellow;
}

.authorization {
  min-width: 60px;
}

.menuImg {
  width: 37px;
  height: 37px;
}

.navItem {
  width: 100%;
  border-bottom: $text solid 1px;
  position: relative;
  align-items: center;
  display: flex;
}

.navItem:last-child {
  border: 0;
}

.navLink {
  color: $base-yellow;
  text-transform: uppercase;
  padding: 22px 50px 22px 15px;
  margin: 2px;
  font-size: 18px;
  display: block;
  text-decoration: none;
  cursor: pointer;
}

.logo {
  width: 90px;
  height: 75px;
}

.additionalNav {
  display: flex;
  align-items: center;
  position: relative;
}

.cartImg {
  @extend .menuImg;
}

.logIn {
  color: $base-yellow;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  margin: 2px;
  cursor: pointer;
}

.logIn:active {
  color: $text;
}

.cartQuantity {
  display: inline-block;
  background: darkgreen;
  color: $base-yellow;
  text-align: center;
  border-radius: 50%;
  padding: 0.5px 4px;
  position: absolute;
  left: 21px;
  top: -7px;
  font-size: 10px;
}

.wishlistQuantity {
  @extend .cartQuantity;
  left: 98px;
  top: 15px;
}
.userPic {
  padding: 10px;
}

//------------------TABLET

@media (min-width: $tablet) {
  .nav {
    position: absolute;
    top: 87px;
  }

  .logIn {
    min-width: 91px;
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  .authorization {
    min-width: 91px;
  }

  .menu {
    padding: 10px 5px 10px 20px;
  }

  .menuImg {
    width: 45px;
    height: 45px;
  }

  .navLine {
    height: 30px;
  }

  .logo {
    width: 110px;
    height: 75px;
  }

  .logIn {
    font-size: 18px;
    line-height: 20px;
    padding-left: 10px;
  }

  .dummy {
    height: 80px;
  }

  .mobileSearch {
    left: 75px;
  }

  .cartQuantity {
    font-size: 11px;
    left: 28px;
    top: -5px;
  }

  .wishlistQuantity {
    left: 99px;
    top: 17px;
  }
}

//------------------TABLET-MAX

@media (min-width: $tablet-max) {
  .dummy {
    height: 90px;
  }

  .menu {
    display: none;
    margin-right: 20px;
  }

  .logIn {
    width: 91px;
  }

  .authorization {
    margin-right: 20px;
  }

  .menuBox {
    display: none;
  }

  .nav {
    position: relative;
    top: 0;
    left: 0;
  }

  .navItem {
    border: 0;
  }

  .navLink {
    padding: 22px 40px 22px 15px;
  }

  .searchWrapper {
    display: none;
  }

  .navList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: none;
  }

  .none {
    display: flex;
  }

  .navItem:hover {
    text-align: center;
    transform: scale(1.2);
    text-shadow: 0 0 15px $active-btn;
  }

  .mobileSearch {
    display: none;
  }

  .searchDesktop {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .searchDesktop:hover {
    transform: scale(1.1);
    align-items: center;
  }

  .logo {
    width: 137px;
    height: 85px;
    margin-left: 15px;
  }

  .navLine {
    height: 32px;
  }

  .menuImg {
    width: 50px;
    height: 50px;
  }

  .cartQuantity {
    left: 32px;
    top: -5px;
  }
  .wishlistQuantity {
    left: 95px;
    top: 12px;
  }
}
