@import '../../styles/variables.scss';

.user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.section {
  padding: 20px 40px;
}

.btnBox {
  @extend .user;
  width: 250px;
  margin: auto;
  padding-top: 20px;
  &:active {
    transform: scale(1.1);
  }
}

.btnOut {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  &:active {
    transform: scale(1.1);
  }
}

.string > input {
  width: 50%;
}

.text {
  color: $text;
  text-transform: uppercase;
}

@media (min-width: $tablet) {
  .user {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    position: relative;
  }

  .btnOut {
    position: fixed;
    top: 15%;
    right: 20px;
  }

  .info {
    width: 60%;
  }
}

@media (min-width: $tablet-max) {
  .info {
    width: 50%;
  }

  .btnOut {
    top: 15%;
    right: 5%;
  }
}
