@import '../../styles/variables.scss';

.form {
  display: flex;
  flex-direction: column;
  position: relative;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  & > * {
    min-width: 47%;
    max-width: 47%;
  }
}

.submitWrapper {
  display: flex;
  justify-content: space-between;
}

.preloader {
  width: 40%;
}

.successReg {
  width: 50%;
  position: absolute;
  padding: 30px;
  background-color: $background;
  color: $base-yellow;
  text-transform: uppercase;
  left: 25%;
  top: 40%;
  text-align: center;
  border-radius: 30px;
}

.anime {
  width: 100%;
  height: 100%;
}
