@import '../../styles/variables.scss';
@media (min-width: $mobile) {
  .productsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 50px 0;
    justify-items: center;
    width: fit-content;
    margin: 25px 0;
  }

  .container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .containerWishlist {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 60px 0;
  }

  .contentProductsWrapper {
    display: flex;
    justify-content: center;
  }

  .filterToolsWrapper {
    width: 220px;
  }

  .noItemsContainer {
    color: $text;
    text-align: center;
    width: 190px;
    margin: 50px 0 0 0;
  }
}

@media (min-width: $tablet) {
  .productsContainer {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px 40px;
  }

  .filterToolsWrapper {
    width: 740px;
  }
}

@media (min-width: $tablet-max) {
  .filterToolsWrapper {
    width: 220px;
  }

  .noItemsContainer {
    width: 650px;
  }
}

@media (min-width: $desktop) {
  .productsContainer {
    grid-template-columns: repeat(4, 1fr);
  }

  .noItemsContainer {
    width: 880px;
  }
}
