@import '../../styles/variables.scss';

.wrapper {
  padding: 0 20px;
  margin-top: 30px;
}

.info {
  display: flex;
  justify-content: space-between;
  color: $text;
  border-bottom: 1px solid $details;
  padding-bottom: 5px;
}

.title {
  font-size: 18px;
  line-height: 20px;
}

.link {
  font-size: 14px;
  border: none;
  background: none;
  color: $base-yellow;
  cursor: pointer;
}

.contentWrapper {
  margin-top: 30px;
}

.itemsBox {
  display: flex;
  flex-direction: column;
  min-width: 60%;
}

.summaSection {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: $base-yellow;
}

.summa {
  display: flex;
  & span {
    margin-right: 5px;
  }
}

.btnBox {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 60px 0;
}

.btn {
  text-transform: uppercase;
  border: 1px solid $base-yellow;
  background: none;
  color: $base-yellow;
  font-size: 20px;
  padding: 10px 30px;
  &:hover {
    cursor: pointer;
    border: 1px solid $text;
    color: $text !important;
    background: $base-yellow;
  }
}

@media (min-width: $tablet) {
  .wrapper {
    padding: 0 50px;
    margin-top: 50px;
  }

  .title {
    font-size: 20px;
    line-height: 23px;
  }

  .contentWrapper {
    display: flex;
    justify-content: space-between;
  }

  .priceWrapper {
    margin-left: 45px;
    display: flex;
    flex-direction: column;
    height: 30%;
    padding: 22px;
    border: 1px solid $details;
    min-width: 30%;
  }

  .summaSection {
    font-size: 20px;
  }

  .summaText {
    margin-right: 10px;
  }

  .btnBox {
    margin: 20px 0 0 0;
    justify-content: center;
  }
}

@media (min-width: $tablet-max) {
  .wrapper {
    padding: 0 120px;
  }

  .priceWrapper {
    min-width: 25%;
  }

  .itemsBox {
    min-width: 70%;
  }
}

@media (min-width: $desktop) {
  .priceWrapper {
    min-width: 20%;
  }
}
