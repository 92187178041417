@import '../../styles/variables.scss';
@import '../../components/Delivery/Delivery.module.scss';

//------------------MOBILE

.payment {
  @extend .delivery;
}

.payment * {
  height: 45px;
}

.submit {
  @extend .btnNext;
}

//------------------TABLET

@media (min-width: $tablet) {
  .payment * {
    height: 53px;
  }
}

//------------------TABLET-MAX

@media (min-width: $tablet-max) {
  .payment * {
    height: 62px;
  }

  .submit {
    margin-top: 65px;
  }
}

//------------------DESKTOP

@media (min-width: $desktop) {
  .payment * {
    height: 65px;
  }
}
