@import '../../styles/variables.scss';

.wrapper {
  width: 250px;
  height: 320px;
  position: relative;
  margin-bottom: 15px;
  &:hover {
    box-shadow: 0 0 10px 1px $active-btn;
    cursor: pointer;
  }
}

.img {
  width: 250px;
  height: 320px;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
  background: #8f8f89d7;
  padding: 5px;
}

.name {
  font-size: 17px;
  color: $text;
  padding-right: 5px;
  align-self: flex-end;
  text-overflow: ellipsis;
}

.priceBox {
  display: flex;
}

.svgNew {
  position: absolute;
  top: 0;
  right: -10px;
  min-width: 100px;
  height: 70px;
}

.svgSale {
  position: absolute;
  bottom: 50px;
  left: 0;
  min-width: 60px;
  height: 60px;
}

.price {
  @extend .name;
  font-size: 22px;
  color: $base-yellow;
  font-weight: 500;
  margin-top: 5px;
}

.previousPrice {
  @extend .price;
  font-size: 18px;
  color: $sale;
  text-decoration: line-through;
}
