@import '../../styles/variables.scss';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  z-index: 5;
}

.background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.2;
  cursor: pointer;
  z-index: 1;
}

.content {
  width: 90vw;
  background-color: black;
  margin: 0 auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.closeButton {
  align-self: flex-end;
  text-transform: uppercase;
  fill: $base-yellow;
  width: 24px;
  height: 24px;
}
