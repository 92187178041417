@import '../../styles/variables.scss';

.wrapper {
  display: block;
  background: #8f8f89a3;
}

.slider {
  min-width: 100%;
}

.carousel-image {
  cursor: pointer;
}

.noSlider {
  width: 100%;
}

.thumbnails {
  display: none;
}
