@import '../../styles/variables.scss';
@media (min-width: $mobile) {
  .accordion:not(:last-of-type)::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #8f8f89;
  }

  .accordion {
    box-shadow: none;
  }

  .checkboxesWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5vw !important;
    text-transform: initial;
  }
}

@media (min-width: $tablet) {
  .checkboxesWrapper {
    margin-left: 2vw !important;
  }

  .accordion {
    box-shadow: none !important;
  }
}
