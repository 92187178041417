@import '../../styles/variables.scss';
@media (min-width: $mobile) {
  .productsPage {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
}

@media (min-width: $desktop) {
  .productsPage {
    margin-bottom: 130px;
  }
}
