@import '../../styles/variables.scss';

.itemSummary {
  margin: 15px;
  min-width: $mobile;
}

.elem {
  height: 1px;
  max-width: 440px;
  background: $details;
  margin: 10px 0;
}

.controls {
  display: flex;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.pageIndicator {
  color: $text;
  font-size: 16px;
}

.pageButton {
  background: $background;
  border: 1px solid $text;
  padding: 5px 5px;
  color: $text;
  cursor: pointer;

  &:disabled {
    color: $details;
  }

  &:hover:enabled {
    background-color: $base-yellow;
    color: $background;
  }
}

.title {
  color: $text;
  font-weight: normal;
  font-size: 16px;
  margin-top: 20px;
}

.empty {
  color: $text;
  font-size: 18px;
  font-weight: 400;
}

@media (min-width: $tablet) {
  .title {
    font-size: 20px;
  }

  .elem {
    margin: 15px 0;
  }
}

@media (min-width: $tablet-max) {
  .itemSummary {
    margin-top: 50px;
  }
}
