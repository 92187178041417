@import '../../styles/variables.scss';

.formBox {
  display: flex;
  flex-direction: column;
}

.checkbox {
  display: flex;
  align-items: center;
  margin: 5px;
}

.btnNext {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

@media (min-width: $tablet) {
  .checkbox {
    margin: 15px;
  }
}

@media (min-width: $tablet-max) {
  .form {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
  }

  .address {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .btnNext {
    margin-top: 30px;
  }
}

//---------------------checkbox

.checkbox > input[type='checkbox'] {
  height: 0;
  width: 0;
}

.checkbox > input[type='checkbox'] + label {
  position: relative;
  font-size: 12px;
  font-weight: 300;
  display: flex;
  margin: 0.6em 0;
  align-items: center;
  color: $details;
  white-space: nowrap;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkbox > input[type='checkbox'] + label > ins {
  position: absolute;
  display: block;
  bottom: 0;
  left: 2em;
  height: 0;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  transition: height 300ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkbox > input[type='checkbox'] + label > ins > i {
  position: absolute;
  bottom: 0;
  font-style: normal;
  color: $base-yellow;
}
.checkbox > input[type='checkbox'] + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 2px solid $details;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.checkbox > input[type='checkbox'] + label:hover,
input[type='checkbox']:focus + label {
  color: $details;
}
.checkbox > input[type='checkbox'] + label:hover > span,
input[type='checkbox']:focus + label > span {
  background: rgba(255, 255, 255, 0.1);
}
.checkbox > input[type='checkbox']:checked + label > ins {
  height: 100%;
}

.checkbox > input[type='checkbox']:checked + label > span {
  border: 0.5em solid $active-btn;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.checkbox > input[type='checkbox']:checked + label > span:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 1px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
