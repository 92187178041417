@import '../../styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  @extend .logo;
  flex-direction: column;
}

.logoImg {
  fill: $base-yellow;
  width: 30%;
}

.title {
  color: $base-yellow;
  margin-top: 30px;
}

.link {
  color: $text;
  text-decoration: underline;
  margin-top: 10px;
}

@media (min-width: $tablet) {
  .wrapper {
    flex-direction: row;
    margin-top: 80px;
  }

  .title {
    font-size: 30px;
  }

  .logoImg {
    margin-right: 30px;
    width: 150px;
  }
}
