@import '../../styles/variables.scss';

.page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.container {
  display: flex;
  border: 1px solid $base-yellow;
  padding: 5px;
  flex-direction: column;
  flex-basis: 200px;
  margin-top: 20px;
  & > p {
    line-break: anywhere;
  }
}
