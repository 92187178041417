@import '../../styles/variables.scss';

.selectorCheckout {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.selectorSign {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  padding: 0 15%;
  position: relative;
  & :first-child:after {
    left: 50%;
    position: absolute;
    content: '';
    height: 20px;
    width: 1px;
    background-color: $base-yellow;
  }
}

.btnCheckout {
  cursor: pointer;
  border: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background-color: transparent;
  font-variant: small-caps;
  color: $details;
}

.btnSign {
  @extend .btnCheckout;
  text-transform: uppercase;
}

.btnCheckoutActive {
  @extend .btnCheckout;
  color: $text;
}

.btnSignActive {
  @extend .btnSign;
  color: $base-yellow;
}

@media (min-width: $tablet) {
  .selectorSign {
    padding: 0 25%;
  }
}
